import {createSlice, Dispatch} from '@reduxjs/toolkit'
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {ActiveTalon, MyQueueClientState} from "./types";
import {showNotification} from "../utils/notification";
import {queueApi} from "../api/queue-api";
import {setError} from "./userSlice";
import {myQueueClientApi} from "../api/ws/myQueueClient-api";

const initialState: MyQueueClientState = {
    talonInfo:null,
    error: false,
}

export const myQueueClientSlice = createSlice({
    name: 'myQueueClient',
    initialState,
    reducers: {
        setTalonInfo: (state, action: PayloadAction<ActiveTalon>) => {
            state.talonInfo = action.payload
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload
        },
    },
})

export const {setTalonInfo} = myQueueClientSlice.actions;

export default myQueueClientSlice.reducer

export const createConnectionTC = (queueId: number, talonId:number) => (dispatch: Dispatch) => {
    try {
        myQueueClientApi.createConnection();
        myQueueClientApi.subscribe(
            () => {
                dispatch(setError(true))
            },
            () => {
                myQueueClientApi.sendClient_queue_id(queueId);
                dispatch(setError(false))
            },
            (errorMessage:string) => {
                showNotification(errorMessage)
            },
            (talons: ActiveTalon[])=>{
                const talon = talons.find(el=>el.id === talonId)
                if (talon) {
                    dispatch(setTalonInfo(talon))
                }
            }
        )
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
    }
}

export const destroyConnectionTC = () => (dispatch: Dispatch) => {
    try {
        myQueueClientApi.destroyConnection()
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
    }
}

export const changeStatusTalonTC = (talon: ActiveTalon, status_id: number, user: string) => (dispatch: Dispatch) => {
    try {
        myQueueClientApi.changeStatusTalon(talon.queue_id, talon.id, status_id, user)
        dispatch(setError(false))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
    }
}

export const getTalonInfoTC = (id:number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getTalonInfo(id);
        if (result.status === 200) {
            dispatch(setTalonInfo(result.data.data))
        } else {
            showNotification('Талон не найден')
        }
    } catch
        (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}