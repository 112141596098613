import {ActiveTalon, Talons, TalonsState} from "./types";
import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {queueApi} from "../api/queue-api";
import {showNotification} from "../utils/notification";
import {setError, setErrorCount} from "./boardSlice";
import {queueAdminApi} from "../api/ws/queueAdmin-api";
import {userApi} from "../api/ws/user-api";
import {setListTalons} from "./userSlice";


const initialState: TalonsState = {
    talons: [],
};

export const talonsSlice = createSlice({
    name: 'talons',
    initialState,
    reducers: {
        setTalons: (state, action: PayloadAction<Talons[]>) => {
            state.talons = action.payload;
        },
        setListTalonsForQueue: (state, action:  PayloadAction<{ listTalons:ActiveTalon[], queueId:number }> ) => {
            state.talons = state.talons.map(el=>el.id === action.payload.queueId? {...el,
                talons:action.payload.listTalons.filter(el => el.queue_id === action.payload.queueId)}: el)
        },
        setStatusTalon: (state, action: PayloadAction<{ talon: ActiveTalon, status_id: number }>) => {
            const {talon, status_id} = action.payload;
            const talons = state.talons?.find(t => t.id === talon.queue_id);
            const talonById = talons?.talons?.find(t => t.id === talon.id)
            if (talonById) {
                talonById.status_id = status_id;
            }
        },
    },
});

export const {setTalons, setStatusTalon, setListTalonsForQueue} = talonsSlice.actions

export default talonsSlice.reducer

export const getTalonsTC = (organization_id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getTalons(organization_id);
        if (result.status === 200) {
            dispatch(setTalons(result.data.data.result))
        } else {
            showNotification('Нет связи с сервером, проверьте подключение')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const changeTalonStatusTC = (talon: ActiveTalon, status_id: number) => async (dispatch: Dispatch) => {
    try {
        await queueAdminApi.changeStatusTalonQueueAdmin(talon.queue_id, talon.id, status_id)
        dispatch(setStatusTalon({talon, status_id}))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const changeStatusTalonTC = (talon: ActiveTalon, status_id: number, user:string) => (dispatch: Dispatch) => {
    try {
         queueAdminApi.changeStatusTalon(talon.queue_id, talon.id, status_id, user)
        dispatch(setStatusTalon({talon, status_id}))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
    }
}

export const changeCitoTalonTC = (talon: ActiveTalon, cito: number) => (dispatch: Dispatch) => {
    try {
         queueAdminApi.changeCitoTalon(talon.queue_id, talon.id, cito)
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
    }
}

export const createConnectionTC = (queueId: number) => (dispatch: Dispatch) => {
    try {
        queueAdminApi.createConnection(queueId)
        queueAdminApi.subscribe(
            (listTalons: ActiveTalon[]) => {
                dispatch(setListTalonsForQueue({listTalons, queueId}))
            },
            () => {
                dispatch(setError(true))
                dispatch(setErrorCount(1))
            },
            () => {
                queueAdminApi.sendClient_queue_id(queueId)
                dispatch(setError(false))
            }
        )
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const destroyConnectionTC = () => (dispatch: Dispatch) => {
    try {
        queueAdminApi.destroyConnection()
        dispatch(setError(false))
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}



