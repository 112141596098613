import {createSlice, Dispatch} from '@reduxjs/toolkit'
import {PayloadAction} from '@reduxjs/toolkit'
import {queueApi} from "../api/queue-api";
import {
    ActiveTalon,
    BoardState,
    BoardView,
    Service, Talon,
} from "./types";
import {showNotification} from "../utils/notification";
import {boardApi} from "../api/ws/board-api";
import {InfoReboot} from "../api/reboot-api";
import {setRebootData} from "./rebootSlice";

const initialState: BoardState = {
    error: false,
    errorCount:0,
    isCall: false,
    callListTalon: [],
    board: {
        board: null,
        services: []
    },
    activeTalon: null,
    listActiveTalonsShow: [],
    boardTalons: {
        listTalon: []
    },
}

export const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {
        setBoard: (state, action: PayloadAction<BoardView>) => {
            state.board = action.payload
        },
        setListTalons: (state, action: PayloadAction<ActiveTalon[]>) => {

            const workListTalon = action.payload.filter((el: ActiveTalon) => {
                    if (el.status_id === 5 || el.status_id === 4 || el.status_id === 6) {
                        return false
                    } else {
                        return true
                    }
                }
            )
            let services_id: number[] = []
            if (state.board.board) {
                const idList = Array.isArray(state.board.board.services) ? state.board.board.services : state.board.board.services.split(',')
                for (let i = 0; i < idList.length; i++) {
                    state.board.services.map((el: Service) => {
                        if (el.internal_id === idList[i]) {
                            services_id.push(el.id)
                            return el.id
                        } else {
                            return ''
                        }
                    })
                }
            }

            const checkForDuplicates = (arr1:any = [], arr2:any = []) => {
                for (let i = 0; i < arr1.length; i++) {
                    if (arr2.includes(arr1[i])) {
                        return true;
                    }
                }
                return false;
            }



            const newListTalon = workListTalon.filter((el) => {
                if (el.list_services) {
                    return checkForDuplicates(state.board.board?.services.split(','), el.list_services.split(','))
                } else {
                    return services_id.filter(elem => elem === el.service_id).length
                }
            })

            state.boardTalons.listTalon = newListTalon
            state.listActiveTalonsShow = newListTalon.filter(el => el.status_id === 2 || el.status_id === 3).sort((a:ActiveTalon, b:ActiveTalon) =>  new Date(b.call_date_time) > new Date(a.call_date_time)?1:-1)

            const newCallListTalon = newListTalon.filter(el => el.status_id === 2)
            if (newCallListTalon.length) {
                for (let i = 0; i < newCallListTalon.length; i++) {
                    if (state.callListTalon.length) {
                        if (state.callListTalon.find(el => el.id !== newCallListTalon[i].id)) {
                            state.callListTalon = [...state.callListTalon, newCallListTalon[i]]
                        }
                    } else {
                        state.callListTalon = [...state.callListTalon, newCallListTalon[i]]
                    }
                }
            }
        },
        setActiveTalon: (state, action: PayloadAction<ActiveTalon | null>) => {
            state.activeTalon = action.payload
        },
        deleteTalonFromcallListTalon: (state, action: PayloadAction<ActiveTalon>) => {
            state.callListTalon = state.callListTalon.filter(el => el.id !== action.payload.id)
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload
        },
        setErrorCount: (state, action: PayloadAction<number>) => {
            state.errorCount = action.payload
        }},
})

export const {setBoard, setListTalons, setActiveTalon, deleteTalonFromcallListTalon, setError, setErrorCount} = boardSlice.actions;

export default boardSlice.reducer

export const getBoardTC = (queueId: number, boardId: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getBoard(queueId, boardId);
        if (result.status === 200) {
            dispatch(setBoard(result.data.data))
            dispatch(setError(false))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const setIsActiveBoardTC = (id: number, isActive: boolean) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.putIsActiveBoard(id, isActive);
        if (result.status === 200) {
            dispatch(setError(false))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const createConnectionTC = (queueId: number, services: string) => (dispatch: Dispatch) => {
    try {
        boardApi.createConnection(queueId, services)
        boardApi.subscribe(
            (talons: ActiveTalon[]) => {
                dispatch(setListTalons(talons))
            },
            () => {
                dispatch(setError(true))
                dispatch(setErrorCount(1))
            },
            () => {
                boardApi.sendClient_queue_id(queueId)
                boardApi.onConnectSendData(queueId, services)
                dispatch(setError(false))
            },
            (data: InfoReboot) => {
               dispatch(setRebootData(data))
            }
        )
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const destroyConnectionTC = () => (dispatch: Dispatch) => {
    try {
        boardApi.destroyConnection()
        dispatch(setError(false))
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const deleteTalonFromcallListTalonTC = (user: string, talon: ActiveTalon, isComplexTablo:boolean | undefined = undefined) => (dispatch: Dispatch) => {
    dispatch(deleteTalonFromcallListTalon(talon))
    try {
        if (isComplexTablo) {
            boardApi.changeStatusTalon(user, talon.queue_id, talon.id, 3, isComplexTablo)
        } else {
            boardApi.changeStatusTalon(user, talon.queue_id, talon.id, 3 )
        }
        dispatch(setError(false))
    } catch (error: any) {
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const setActiveTalonTC = (talon: ActiveTalon | null) => (dispatch: Dispatch) => {
    dispatch(setActiveTalon(talon))
}

export const setErrorCountTC = (count:number) => (dispatch: Dispatch) => {
    dispatch(setErrorCount(count))
}






