import {createSlice, Dispatch} from '@reduxjs/toolkit'
import {PayloadAction} from '@reduxjs/toolkit'
import {queueApi} from "../api/queue-api";
import {
    ActiveTalon,
    Operators, Packet, Service,
    UserState
} from "./types";
import {showNotification, showNotificationInfo} from "../utils/notification";
import {userApi} from "../api/ws/user-api";
import {setRebootData} from "./rebootSlice";

const initialState: UserState = {
    error: false,
    errorCount: 0,
    operators: {
        services: [],
        users: [],
        user: null
    },
    activeCallTalon: null,
    callListTalon: [],
    operatorsTalons: {
        listTalon: [],
        listTalonRecord: [],
        listTalonStop: []
    },
    packet: null,
    services:[]
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setOperators: (state, action: PayloadAction<Operators>) => {
            state.operators = action.payload
        },
        setUser: (state, action: PayloadAction<number>) => {
            state.operators.user = state.operators.users.filter(el => el.id === action.payload)[0]
        },
        clearUser: (state) => {
            state.operators.user = null
        },
        setListTalons: (state, action: PayloadAction<ActiveTalon[]>) => {
            let services_id: number[] = []
            if (state.operators.user) {
                const idList = Array.isArray(state.operators.user.services) ? state.operators.user.services : state.operators.user.services.split(',')
                for (let i = 0; i < idList.length; i++) {
                    state.operators.services.map((el: Service) => {
                        if (el.internal_id === idList[i]) {
                            services_id.push(el.id)
                            return el.id
                        } else {
                            return ''
                        }
                    })
                }
            }

            const checkForDuplicates = (arr1: any = [], arr2: any = []) => {
                for (let i = 0; i < arr1.length; i++) {
                    if (arr2.includes(arr1[i])) {
                        return true;
                    }
                }
                return false;
            }

            const newListTalon = action.payload.filter((el) => {
                if (el.list_services) {
                    return checkForDuplicates(state.operators?.user?.services.split(','), el.list_services.split(','))
                } else {
                    return services_id.filter(elem => elem === el.service_id).length
                }
            }).filter(el => el.status_id !== 5 && el.status_id !== 4 && el.status_id !== 6).filter((el) => {
                //проверка на критерии в талонах и есть ли они у оператора
                if (state.operators?.user?.criterion_ids) {
                    if (Number(el.criterion_id)) {
                        // @ts-ignore
                        if ( state.operators?.user?.criterion_ids.split(',').map(Number).includes(el.criterion_id)) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                } else {
                    return true
                }
                //Проверка на зависимость услуги у оператора от другой услуги
            }).filter((elem) => {
                if (state.services.find( el => el.internal_id === state.operators.user?.services)?.depend_service) {
                    const dependService = state.services.find( el => el.internal_id === state.operators.user?.services)?.depend_service
                    // @ts-ignore
                    if (dependService && elem.list_services && elem.list_services.split(',').some(element => dependService.split(',').includes(element))) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            }).filter((el) => {
                //проверка талона, пришел ли он после дообследований обратно
                //if (state.operators?.user?.return_services) {
                if (el.return_user_id && !el.return_services) {
                    if (state.operators?.user?.id === el.return_user_id) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            }).reduce<ActiveTalon[]>((acc, item:ActiveTalon) => {
                //делаем кто с cito в начало списка
                if (item.cito) {
                    // @ts-ignore
                    acc.unshift(item); // Добавляем в начало для cito = 1
                } else {
                    // @ts-ignore
                    acc.push(item); // Добавляем в конец для cito = 0
                }
                return acc;
            }, [])

            // смотрим изменился ли статус у активного талона
            if (newListTalon.find(el => el.id === state.activeCallTalon?.id)) {
                // @ts-ignore
                state.activeCallTalon = newListTalon.find(el => el.id === state.activeCallTalon?.id && el.user_id===state.operators.user.id)
            }

            //const newListTalon = action.payload.filter((el) => services_id.filter(elem => elem === el.service_id).length).filter(el => el.status_id !== 5 && el.status_id !== 4)
            state.operatorsTalons.listTalon = newListTalon.filter(el => !el.is_record)
            state.operatorsTalons.listTalonRecord = newListTalon.filter(el => el.is_record && el.is_active)
            const newListTalonAllCall = newListTalon.filter((el: ActiveTalon) => {
                if (state.activeCallTalon) {
                    if (el.status_id === 1 && el.id !== state.activeCallTalon.id) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    if (el.status_id === 1) {
                        return true
                    } else {
                        return false
                    }
                }

            })

            if (state.operators.user && state.operators.user.role === 2) {
                state.callListTalon = newListTalonAllCall.filter(el => el.role === 2)
            } else {
                state.callListTalon = newListTalonAllCall.filter(el => {
                    if (el.role === 1 || el.role === 0) {
                        return true
                    } else {
                        return false
                    }
                })
            }

            //Талон в стоп листе
            state.operatorsTalons.listTalonStop = action.payload.filter((el) => {
                if (el.list_services) {
                    return checkForDuplicates(state.operators?.user?.services.split(','), el.list_services.split(','))
                } else {
                    return services_id.filter(elem => elem === el.service_id).length
                }
            }).filter(el => el.status_id === 6)
        },
        setActiveTalon: (state, action: PayloadAction<ActiveTalon>) => {
            state.activeCallTalon = action.payload
            state.callListTalon = state.callListTalon.filter(el => el.id === action.payload.id)
        },
        deleteActiveTalon: (state) => {
            state.activeCallTalon = null
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload
        },
        setErrorCount: (state, action: PayloadAction<number>) => {
            state.errorCount = action.payload
        },
        setPacket: (state, action: PayloadAction<Packet | null>) => {
            state.packet = action.payload
        },
        setService: (state, action: PayloadAction<Service[]>) => {
            state.services = action.payload
        },
    },
})

export const {setOperators, setUser, clearUser, setListTalons, setActiveTalon, deleteActiveTalon, setError, setErrorCount, setPacket, setService} = userSlice.actions

export default userSlice.reducer

export const getUsersTC = (id: number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getUsers(id);
        if (result.status === 200) {
            dispatch(setOperators(result.data.data))
            dispatch(setError(false))
        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const setIsActiveUserTC = (id: number, isActive: boolean) => async (dispatch: Dispatch) => {
    dispatch(setUser(id))

    try {
        let result = await queueApi.putIsActiveUser(id, isActive);
        if (result.status === 200) {
            dispatch(setError(false))

        } else {
            showNotification('Не верные данные, проверьте запрос')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const createConnectionTC = (queueId: number, services: string) => (dispatch: Dispatch) => {
    try {
        userApi.createConnection(queueId, services)
        userApi.subscribe(
            (talons: ActiveTalon[]) => {
                dispatch(setListTalons(talons))
            },
            () => {
                dispatch(setError(true))
                dispatch(setErrorCount(1))
            },
            () => {
                userApi.sendClient_queue_id(queueId)
                userApi.onConnectSendData(queueId, services)
                dispatch(setError(false))
            },
            (data)=>{
                dispatch(setRebootData(data))
            }
        )
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const destroyConnectionTC = () => (dispatch: Dispatch) => {
    try {
        userApi.destroyConnection()
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const setStatusTalonTC = (queueId: number, talonId: number, userId: number, is_window: boolean, place: string, status: number) => (dispatch: Dispatch) => {
    try {
        userApi.setStatusTalon(queueId, talonId, userId, is_window, place, status)
        dispatch(setError(false))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}


export const changeStatusTalonTC = (user:string, talon: ActiveTalon, status_id: number, activeTalon?: ActiveTalon | null | undefined) => (dispatch: Dispatch) => {
    dispatch(deleteActiveTalon())
    try {
        userApi.changeStatusTalon(talon.queue_id, talon.id, status_id, user)
        dispatch(setError(false))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))

    }
}

export const changeStatusTalonComplexTC = (user:string, delay_call:number, user_services: string | undefined, talon: ActiveTalon, status_id: number, activeTalon?: ActiveTalon | null | undefined) => (dispatch: Dispatch) => {
    dispatch(deleteActiveTalon())

    try {
        if (status_id===5) {
            const delay = delay_call * 1000
            userApi.changeStatusTalonComplex(user, talon.queue_id, talon.id, 7, user_services, delay) // промежуточный статус для задержки
        } else {
            userApi.changeStatusTalonComplex(user, talon.queue_id, talon.id, status_id, user_services, 0)
            dispatch(setError(false))
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const changeServiceTalonTC = (talon: ActiveTalon, service: Service, status_id: number) => (dispatch: Dispatch) => {
    if (status_id === 1 || status_id === 2) {
        dispatch(deleteActiveTalon())
    }
    try {
        userApi.changeServiceTalon(talon, service, status_id)
        dispatch(setError(false))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const changePacketTalonTC = (talon: ActiveTalon, packet: Packet, status_id: number) => (dispatch: Dispatch) => {
    if (status_id === 1 || status_id === 2) {
        dispatch(deleteActiveTalon())
    }
    try {
        userApi.changePacketTalon(talon, packet, status_id)
        dispatch(setError(false))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const changeRoleTalonTC = (talon: ActiveTalon, role: number, fio: string, service_dop: number, date: string) => (dispatch: Dispatch) => {
    dispatch(deleteActiveTalon())
    try {
        userApi.changeRoleTalon(talon, role, fio, service_dop, date)
        dispatch(setError(false))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}


export const setActiveTalonTC = (talon: ActiveTalon) => (dispatch: Dispatch) => {
    dispatch(setActiveTalon(talon))
}

export const deleteActiveTalonTC = () => (dispatch: Dispatch) => {
    dispatch(deleteActiveTalon())
}

export const setErrorCountTC = (count: number) => (dispatch: Dispatch) => {
    dispatch(setErrorCount(count))
}

export const getPacketTC = (packet_id: number | undefined) => async (dispatch: Dispatch) => {
    if (packet_id) {
        try {
            const result = await queueApi.getPacket(packet_id)
            if (result.status === 200) {
                dispatch(setPacket(result.data.data.packet))
            }
        } catch (error: any) {
            showNotification('Нет связи с сервером, проверьте подключение')
        }
    }
}

export const clearPacketTC = () => async (dispatch: Dispatch) => {
    dispatch(setPacket(null))
}

export const getServicesForUserTC = (queue_id:number) => async (dispatch: Dispatch) => {
    try {
        let result = await queueApi.getServices(queue_id);
        if (result.status === 200) {
            dispatch(setService(result.data.data.result))
        } else {
            showNotification('Нет связи с сервером, проверьте подключение')
        }
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
    }
}

export const addServiceInTalonComplexTC = (queue_id: number, talon_id: number, internal_id: string) => (dispatch: Dispatch) => {
    try {
        userApi.addServiceInTalonComplex(queue_id, talon_id, internal_id)
        dispatch(setError(false))
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}

export const addReturnServiceInTalonComplexTC = (queue_id: number, talon_id: number, user_services: string, user_id: number) => (dispatch: Dispatch) => {
    try {
        userApi.addReturnServiceInTalonComplex(queue_id, talon_id, user_services, user_id)
        dispatch(setError(false))
        showNotificationInfo('После прохождения всех услуг, пациент будет перенаправлен к вам')
    } catch (error: any) {
        showNotification('Нет связи с сервером, проверьте подключение')
        dispatch(setError(true))
        dispatch(setErrorCount(1))
    }
}




