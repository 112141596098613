export interface AuthState {
    isLogin: boolean;
    userActive: UserActive | null;
    userEmail?: string;
    isVerify: boolean;
    isVerifyCode: boolean;
    isForgotModal: boolean;
    isLoginModal: boolean;
    resetPassword: boolean;
    isForgotRedirect: boolean;
    users: UserActive[];
    organizationInfo: OrganizationState;
}

export interface UserActive {
    login?: string;
    password?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    organization_id?: number;
    role_id?: number;
}

export interface QueueInfoState {
    queue: QueueData | null;
    queues: QueueData[];
    complexQueue: ComplexQueueData | null
    complexQueues: ComplexQueueData[]
}

export interface LogoState {
    id: number;
    title: string;
    path: File | string;
    organization_id: number;
}

export interface Logo extends Omit<LogoState, "path" | "id"> {
    file: File | string;
}

export interface QueueData {
    id: number;
    title: string;
    services: ServiceData[];
}

export interface ComplexQueueData {
    id: string
    title: string
    users: number
    boards: number
    kiosks: number
    packets: PacketComplex[]
}

export interface PacketComplex {
    id: number
    title: string
    talons: TalonComplex[]
}

export interface TalonComplex {
    day: string
    count: number
}

export interface ServiceData {
    id: number;
    title: string;
    users: number;
    boards: number;
    kiosks: number;
    talons: TalonData[];
}

export interface TalonData {
    count: number;
    date: string;
}

export interface MiniBoardState {
    error: boolean;
    errorCount: number;
    user: UserAdd | null;
    listTalon: ActiveTalon[];
    activeTalon: ActiveTalon | null;
    organizationId: number
}

export interface QueueState {
    queueList: Queue[] | [];
    queueListForVideos: QueuesForVideos[];
    servicesList: Service[];
    queuesListByQueu: BoardsByQueusState[];
    queue:QueueShort | null
}

export interface QueueComplexState {
    queueListComplex: QueueComplex[] | [];
}

export interface UserState {
    error: boolean;
    errorCount: number;
    operators: Operators;
    activeCallTalon: ActiveTalon | null;
    callListTalon: ActiveTalon[];
    operatorsTalons: UserTalons;
    packet: Packet | null;
    services: Service[];
}

export interface BoardState {
    error: boolean;
    errorCount: number;
    isCall: boolean;
    callListTalon: ActiveTalon[];
    activeTalon: ActiveTalon | null;
    board: BoardView;
    listActiveTalonsShow: ActiveTalon[];
    boardTalons: BoardTalons;
}

export interface KioskState {
    kiosk: Kiosk;
    currentTicketNumber: CurrentTicketNumber | null;
    isLoading: boolean;
    error: boolean;
    errorCount: number;
}

export interface Operators {
    services: Service[];
    users: UserAdd[];
    user: UserAdd | null;
}

export interface Queue {
    id: number;
    title: string;
    address: string;
    is_complex?: boolean;
    organization_id: number | undefined;
    linkKiosk?: string;
    linkTV?: string;
    linkOperator?: string;
    services: Service[];
    users: User[];
    boards: Board[];
    kiosks: KioskAdd[];
}

export interface QueueShort {
    id: number;
    title: string;
    address: string;
    organization_id: number;
    is_complex:boolean
}

export interface QueueComplex {
    id: number;
    title: string;
    address: string;
    organization_id: number | undefined;
    is_complex: boolean;
    linkKiosk?: string;
    linkTV?: string;
    linkOperator?: string;
    services: Service[];
    packets: Packet[];
    criterions: Criterion[];
    users: User[];
    boards: Board[];
    kiosks: KioskAdd[];
}

export interface Kiosk {
    kiosk: KioskInt | null;
    services: Service[];
}

export interface KioskInt {
    id: number;
    title: string;
    quieue_id: number;
    services: string;
    complex: ComplexKioskModel | null;
    is_qrcode:boolean,
    is_random_number:boolean
}

export interface ComplexKioskModel {
    id: number;
    title: string;
    packet: number;
    letter: string;
    start_time: string;
    end_time: string;
    is_pay: boolean;
    children: ComplexKioskModel[];
    title_select:string;
    criterion: number
}

export interface CurrentTicketNumber {
    id: number;
    talon_id: number;
}

export interface BoardView {
    board: BoardInt | null;
    services: Service[];
}

export interface BoardInt {
    id: number;
    title: string;
    quieue_id: number;
    services: string;
    is_animation: boolean;
    videos_ids?: string;
}

export interface KioskAdd {
    id: number;
    title: string;
    services: string[];
    queue_id?: number;
    complex?: ComplexKioskModel | null;
    is_qrcode:boolean,
    is_random_number:boolean
}

export interface QueueAdd {
    id?: number;
    title: string;
    address: string;
    organization_id: number | undefined;
    linkKiosk?: string;
    linkTV?: string;
    linkOperator?: string;
    services: Service[];
    users: User[];
    boards: Board[];
    kiosks: KioskAdd[];
}

export interface QueueAddComplex {
    id?: number;
    title: string;
    address: string;
    organization_id: number | undefined;
    linkKiosk?: string;
    linkTV?: string;
    linkOperator?: string;
    services: Service[];
    packets: Packet[];
    criterions: Criterion[];
    users: User[];
    boards: Board[];
    kiosks: KioskAdd[];
}

export interface QueueUpdate {
    id: number | undefined;
    title: string;
    address: string;
}

export interface BoardTalons {
    listTalon: ActiveTalon[];
}

export interface UserTalons {
    listTalon: ActiveTalon[];
    listTalonRecord: ActiveTalon[];
    listTalonStop: ActiveTalon[]
}

export interface ActiveTalon {
    id: number;
    letter: string;
    queue_id: number;
    talon_id: number;
    service_id: number;
    create_time: string;
    status_id: number;
    is_window: boolean;
    place_call: string;
    user_id: number;
    is_pay: boolean;
    service_to: number;
    service_from: number;
    role: number;
    fio: string;
    list_services: string;
    packet_id?: number;
    first_packet: number
    criterion_id?:number;
    completed_services:string;
    packet_to: number;
    packet_from: number;
    call_date_time: string;
    return_services:string;
    return_user_id: number;
    call_count: number;
    is_record:boolean;
    year_birth:number;
    cito: boolean;
    is_active:boolean;
}

export interface Service {
    id: number;
    letter: string;
    title: string;
    start_time: string;
    end_time: string;
    internal_id: string;
    queue_id: number;
    is_pay: boolean;
    title_button: string;
    depend_service:string[];
}

export interface QueueSend {
    id?: number;
    title: string;
    address: string;
    organization_id: number | undefined;
    linkKiosk?: string;
    linkTV?: string;
    linkOperator?: string;
    services: Service[];
    users: UserSend[];
    boards: BoardSend[];
    kiosks: KioskSend[];
}

export interface QueueSendComplex {
    id?: number;
    title: string;
    address: string;
    organization_id: number | undefined;
    linkKiosk?: string;
    linkTV?: string;
    linkOperator?: string;
    services: Service[];
    packets: PacketSend[];
    users: UserSend[];
    boards: BoardSend[];
    kiosks: KioskSend[];
}

export interface UserSend {
    name: string;
    place: string;
    services: string;
    status: boolean;
    isActive: boolean;
    dop_service: boolean;
    is_window: boolean;
    service_dop: string;
    two_blocks: boolean;
}

export interface PacketSend {
    title: string;
    services: string;
    message: string;
}

export interface BoardSend {
    title: string;
    services: string;
    is_animation: boolean;
}

export interface KioskSend {
    title: string;
    services: string;
}

export interface User {
    id: number;
    name: string;
    place: string;
    services: string[];
    status: boolean;
    isActive: boolean;
    dop_service: boolean;
    is_window: boolean;
    role: number;
    service_dop: string;
    two_blocks: boolean;
    isComplex?: boolean;
    criterion_ids?:string[] | string;
    services_for_add: string[];
    packets_transfer:string[],
    delay_call:number,
    return_services: string[];
}

export interface UserAdd {
    id: number;
    name: string;
    place: string;
    services: string;
    status: boolean;
    isActive: boolean;
    dop_service: boolean;
    is_window: boolean;
    role: number;
    service_dop: string;
    two_blocks: boolean;
    isComplex?: boolean;
    criterion_ids?:string[] | string;
    services_for_add: string[];
    packets_transfer: string;
    delay_call:number;
    return_services: string[];
}

export interface Board {
    id: number;
    title: string;
    services: string[];
    is_animation: boolean;
    videos_ids:string | null;
    queue_id?: number;
}

export interface Packet {
    id: number;
    queue_id?: number;
    title: string;
    message: string;
    services: string[];
    next_packet_id: number;
    services_stop_list: string;
    letter: string;
    start_time: string;
    end_time: string;
}

export interface Talon {
    id: number;
    letter: string;
    queue_id: number;
    board_id: number;
}

export interface ServiceTitle {
    title: string;
    internal_id: string;
}

export interface PacketTitle {
    title: string;
    id: string;
}

export interface MyQueueClientState {
    talonInfo: ActiveTalon | null;
    error: boolean;
}

export interface PacketState {
    packetsList: QueueWithPacket[];
    packet: Packet | null;
}

export interface CriterionState {
    criterionList: Criterion[];
    criterion: Criterion | null;
}

export interface Criterion {
    id:number
    title:string,
    title_select:string,
    criterions_units?: CriterionUnit[],
    queue_id?: number;
}

export interface CriterionUnit {
    id:number
    title:string,
    criterion_id: number,
}

export interface ServiceState {
    services: Service[];
}

export interface QueueWithPacket {
    id: number;
    title: string;
    address: string;
    is_complex: boolean;
    organization_id: number;
    packets: Packet[];
}

export interface Talons {
    id: number;
    title: string;
    address: string;
    is_complex: number;
    organization_id: number;
    talons: ActiveTalon[];
}

export interface TalonsState {
    talons: Talons[];
}

export interface OrganizationState {
    id: number;
    title: string;
    title_short: string;
    start_at: string;
    end_at: string | null
    is_blocked: number;
}
export interface OrganizationUpdate {
    id: number;
    title: string;
    title_short: string;
}

export interface GetOrganizationsResponse {
  status: boolean;
  data: {
    result: OrganizationState[];
  };
}

export interface Videos {
    id: number;
    title: string;
    path: string;
    organization_id: string;
}

export interface VideosProgress {
    progress: string;
    id: string;
    size: number;
}

export interface QueuesForVideos extends Omit<Talons, "organization_id" | "talons"> {
    organization_id: number;
}

export interface BoardsByQueus extends Omit<Board, "is_animation"> {
    is_animation: number;
    is_active: number;
    videos_ids: string | null;
    queue_id: number;
}

export interface BoardsByQueusState extends Omit<BoardsByQueus, "videos_ids"> {
    videos_ids: Array<string>;
}

export interface BoardsUpdate {
    id: number;
    videos_ids: string;
}

export interface VerifyCode {
    verify_code: number;
    email: string;
    login?: string;
    password?: string;
}

export interface SignUpData extends Omit<UserActive, "last_name" | "organization_id" | "role_id"> {}
export interface SignInData extends Omit<SignUpData, "first_name" | "email"> {}
export interface Login extends Omit<VerifyCode, "verify_code" | "email"> {}
export interface ForgotPassword extends Omit<VerifyCode, "login"> {}
export interface PasswordReset {
    email: string;
    password?: string;
    verify_code?: number;
}

export interface PriceType {
    title: string;
    descrition: string;
    price: string;
    values: string[];
}
export interface AddUsers {
    data: Array<UserActive & {verify: number}>;
}

export type BtnActionType = "delete" | "edit";

export enum SupportRequestMessageType {
    ADMIN = 1,
    ADMIN_QUEUE = 2,
    OPERATOR = 3,
    CLIENT = 4
}

export enum SupportRequestStatuses  {
    SENT = 1, // Отправлено
    IN_PROGRESS = 2, // На рассмотрении
    PROCESSED = 3 // Обработано
}

export interface SupportRequest {
    id: number | null
    type_id: SupportRequestMessageType | undefined,
    date: string,
    organization_id: number | undefined,
    name: string,
    tel_email: string
    message: string
    login: string | undefined,
    status: SupportRequestStatuses,
    answer: string
}

export interface SupportRequestsState {
    requests: SupportRequest[]
}

export enum RoleID {
    S_ADMIN = 1,
    ADMIN = 2,
    ADMIN_QUEUE = 3
}

export interface SuperAdminState {
  organizations: OrganizationState[];
  users: Client[];
  requests: SupportRequest[];
}

export interface Client {
  id: number;
  login: string;
  first_name: string;
  last_name: string;
  email: string;
  organization_id: number;
  role_id: number;
  verify: number;
}

export interface GetAllUsersResponse {
  status: boolean;
  data: {
    result: Client[];
  };
}

export interface GetSupportMessagesResponse {
    status: boolean;
    data: {
        result: SupportRequest[];
    };
}

export interface newTalonByRecord {
    queue_id:number,
    service_id:number,
    list_services?: string,
    packet_id:number,
    letter?:string,
    is_pay:boolean,
    create_time:string,
    selectCriterionUnit:number,
    is_random_number:boolean,
    status_id: number,
    fio: string
}